import Head from 'next/head'
import { urlPath } from 'src/utils/lpPath'

const NotFoundErrorPage = () => (
  <div>
    <Head>
      <title>404｜アット葬儀（@葬儀）</title>
      <meta name="description" content="お探しのページが見つかりません。" />
    </Head>
    <div className="errorPage">
      <h1>お探しのページが見つかりません。</h1>
      <p className="mt-8 mb-16">URLが間違っているか、削除された可能性があります。</p>
      <a className="link" href={urlPath.top}>
        トップページへ
      </a>
    </div>
  </div>
)

export default NotFoundErrorPage
